import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { Col, Row, Container } from "react-bootstrap"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { textHighlight } from "../../utils"
import ScrollArrow from "../UI/ScrollArrow"
import Link from "../UI/Link"
import { PillButton } from "../UI/PillButton"
import { Share } from "../UI/Share"
import {
  searchBoxContainer,
  searchBox,
  open,
  close,
  suggestionElement,
  thumbnail
} from "./FrequentlyAskedQuestions.module.scss"
import { makeFAQSchema } from "../../utils/makePageSchema"
import { Helmet } from "react-helmet"

export const fragment = graphql`
  fragment frequentlyAskedQuestions on WpPage_Flexlayouts_FlexibleLayouts_FrequentlyAskedQuestions {
    sectionHeader
    questionsAndAnswers {
      answer
      schemaAnswer
      links {
        link {
          url
          title
        }
      }
      question
      storyTitle
      storyLink {
        title
        url
      }
      storyImage {
        ...GatsbyImageQuery
      }
    }
  }
`

const FrequentlyAskedQuestions = ({
  questionsAndAnswers,
  sectionHeader
}) => {
  const [suggestion, setSuggestion] = useState([""])
  const [searchText, setSearchText] = useState("")
  const [display, setDisplay] = useState(false)
  const myRef = useRef(null)
  const scrollOption = {
    behavior: "smooth",
    block: "start"
  }

  const refs = questionsAndAnswers.reduce((acc, value) => {
    acc[value.question] = React.createRef()
    return acc
  }, {})

  const handleClick = (id, e) => {
    let suggestionElement = e.target.classList[0].includes("suggestionElement")
    suggestionElement && setSearchText("")
    refs[id].current.scrollIntoView(scrollOption)
  }
  const scrollToTop = () => myRef.current.scrollIntoView(scrollOption)

  useEffect(() => {
    searchForQuestion(searchText)
  }, [searchText])

  const searchForQuestion = searchText => {
    let matches = questionsAndAnswers
      .map(data => data.question)
      .filter(question => {
        const regex = new RegExp(`${searchText}`, "gi")
        return question.match(regex)
      })
    setSuggestion(matches)
    setDisplay(true)
  }

  return (
    <section className={`bg-pale-grey pt-0`} ref={myRef}>
       <Helmet>
          <script className='structured-data-list' type="application/ld+json">{makeFAQSchema(questionsAndAnswers)}</script>
        </Helmet>
      <Container>
        <div
          id="searchBox"
          className={`${searchBoxContainer} bg-purple d-flex justify-content-start align-items-center`}>
          <span className="m-0 me-5">Type your question:</span>
          <div className="w-100 position-relative">
            <input
              type="text"
              value={searchText}
              className={`${searchBox} ${
                searchText !== "" && suggestion.length ? open : close
              }`}
              onChange={e => setSearchText(e.target.value)}
            />
            {display === true && searchText !== "" && (
              <div className="position-absolute w-100">
                {suggestion &&
                  suggestion.map(hint => {
                    const hightlightedText = textHighlight(hint, searchText)
                    return (
                      <a
                        className={`${suggestionElement} d-block bg-white secondary`}
                        dangerouslySetInnerHTML={{ __html: hightlightedText }}
                        onClick={e => handleClick(hint, e)}
                      />
                    )
                  })}
              </div>
            )}
          </div>
        </div>

        <h2 className="pt-5">{sectionHeader}</h2>

        <div className="mb-5">
          {questionsAndAnswers.map((item, index) => {
            if (index <= 6) {
              return (
                <PillButton
                  title={item.question}
                  classes="me-3 mb-3"
                  onClick={e => handleClick(item.question, e)}
                />
              )
            }
          })}
        </div>

        <Container className="bg-white pt-5">
          {questionsAndAnswers.map(item => {
            const {
              storyImage,
              question,
              altText,
              storyTitle,
              answer,
              storyLink,
              links
            } = item
            const smallImg = getImage(storyImage?.localFile)
            return (
              <>
                {" "}
                <h3
                  className="quicksand-500 px-lg-3 px-xl-7"
                  ref={refs[question]}>
                  {question}
                </h3>
                <Row className="flex-column flex-lg-row py-5 px-lg-4 px-xl-7 mx-lg-auto justify-content-lg-between">
                  <div dangerouslySetInnerHTML={{ __html: answer }} />
                  {smallImg && (
                    <Container>
                      <Row className={` align-items-center bg-pale-grey my-4`}>
                        <Col xs={4} lg={3} className=" d-flex px-0">
                          <GatsbyImage
                            image={smallImg}
                            alt={altText}
                            className={thumbnail}
                          />
                        </Col>
                        <Col xs={8} lg={9} className="mx-auto">
                          <div className={`d-flex flex-column`}>
                            {storyTitle && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: storyTitle
                                }}
                                className="font-weight-bold text-start"
                              />
                            )}
                            {storyLink && (
                              <Link
                                classes="linkClass"
                                link={storyLink.url}
                                title={storyLink.title}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  <div>
                    {links &&
                      links.length &&
                      links.map(link => {
                        let { title, url } = link.link
                        return (
                          <Link
                            classes="linkClass d-block pt-2"
                            link={url}
                            title={title}
                          />
                        )
                      })}
                    <div className={`linkClass pt-2`} onClick={scrollToTop}>
                      Back to top
                    </div>
                    <Share link={`#title`} className="mt-4" />
                  </div>
                </Row>
              </>
            )
          })}
        </Container>
      </Container>
      <ScrollArrow />
    </section>
  )
}

export default FrequentlyAskedQuestions

