import React, { useState } from "react"
import { ArrowUpCircle } from "react-bootstrap-icons"
import { scrollTopClass } from "./ScrollArrow.module.scss"
import { useScrollPosition } from "../../hooks/useScrollPosition"

const ScrollArrow = () => {
  const [hideOnScroll, setHideOnScroll] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y && currPos.y < -300
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow)
      }
    },
    [hideOnScroll],
    false,
    false,
    200
  )

  const scrollTop = () => {
    window.scrollTo({ top: 400, behavior: "smooth" })
  }
  return (
    <div>
      <ArrowUpCircle
        className={scrollTopClass}
        onClick={scrollTop}
        style={{ height: 40, display: hideOnScroll ? " flex" : "none" }}
      />
    </div>
  )
}

export default ScrollArrow
